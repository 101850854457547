<template>
  <div class="flex h-full w-full flex-col items-center justify-center">
    <img src="/eventful-logo.png" class="mb-8 w-40" />
    <form @submit.prevent.stop="login()">
      <h1 class="mb-6 text-center text-xl font-bold">Login</h1>
      <ContentContainer
        class="flex w-96 items-center justify-center border border-zinc-200 bg-white"
      >
        <input
          id="phoneInput"
          ref="phoneInput"
          v-model="phone"
          class="w-80 rounded-md border-0"
          type="tel"
          @change="() => reset()"
          @blur="() => validate()"
        />
      </ContentContainer>
      <p v-if="validationErrorCode !== null" class="sm mt-3 text-red-500">
        {{ validationErrors[validationErrorCode] }}
      </p>
      <p v-if="loginError" class="sm mt-3 text-red-500">
        {{ loginError }}
      </p>
      <p class="mt-3 w-96 text-xs text-zinc-700">
        To sign in, we'll send you a text message with a verification code.
        Message data rates may apply.
      </p>
      <button
        type="submit"
        :disabled="isLoading"
        class="mt-12 w-96 rounded-md bg-amber-100 py-2 text-amber-700 shadow-md hover:bg-amber-200 disabled:bg-zinc-200 disabled:text-zinc-400"
      >
        Sign In
      </button>
    </form>
    <p class="mt-10 text-center text-xs font-medium text-zinc-600">
      By logging in, you agree with our
      <NuxtLink class="text-blue-500 hover:underline" to="/terms"
        >terms of use</NuxtLink
      >
      and
      <NuxtLink class="text-blue-500 hover:underline" to="/privacy"
        >privacy policy</NuxtLink
      >
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { Plugin } from 'intl-tel-input';
import { parsePhoneNumber } from 'awesome-phonenumber';

const { exceptionLogger } = useExceptionLogger();

const isLoading = ref(false);

const phoneInput = ref<HTMLInputElement | null>(null);
const phone = ref<string | null>(null);

const iti = ref<null | Plugin>();

onMounted(async () => {
  const intlTelInput = (await import('intl-tel-input')).default;
  if (!phoneInput.value) {
    return;
  }

  iti.value = intlTelInput(phoneInput.value, {
    initialCountry: 'auto',
    geoIpLookup: async (callback) => {
      let country = 'US';
      try {
        const data = await $fetch<{ country: string }>('https://ipinfo.io', {
          method: 'GET',
          query: { token: 'e74a6e29d520dd' },
        });

        if (data?.country) {
          country = data.country;
        }
      } catch (e) {
        exceptionLogger(e);
      }
      callback(country);
    },
    utilsScript:
      'https://cdn.jsdelivr.net/npm/intl-tel-input@19.5.5/build/js/utils.js',
  });
});

const validationErrors = [
  'Invalid phone number',
  'Invalid country code',
  'Number too short',
  'Number too long',
  'Invalid phone number',
];

const validationErrorCode = ref<null | number>(null);

const validate = () => {
  const trimmedPhone = phone.value?.trim();
  if (trimmedPhone && iti.value?.isValidNumber()) {
    validationErrorCode.value = null;
    iti.value?.setNumber(trimmedPhone);
    return true;
  } else {
    validationErrorCode.value = iti.value?.getValidationError() ?? 0;
    if (validationErrorCode.value < 0) {
      validationErrorCode.value = 0;
    }
  }

  return false;
};

const $route = useRoute();
const from = $route.query.from ? `&from=${$route.query.from}` : '';

const $router = useRouter();

const supabase = useSupabaseClient();

const loginError = ref('');

const reset = () => {
  loginError.value = '';
  validationErrorCode.value = null;
};

const login = async () => {
  reset();
  isLoading.value = true;
  if (validate()) {
    const formattedNumber = iti.value?.getNumber();

    if (formattedNumber) {
      const normalizedE164 = parsePhoneNumber(formattedNumber).number?.e164;

      if (!normalizedE164) {
        exceptionLogger(`Normalization error ${formattedNumber}`);
        loginError.value = "Couldn't sign in. Is the phone number is correct?";

        return;
      }

      try {
        const { error } = await supabase.auth.signInWithOtp({
          phone: normalizedE164,
        });
        if (error) {
          exceptionLogger(error);
          loginError.value =
            "Couldn't sign in, make sure the phone number is correct";
        } else {
          $router.push(`/login/confirm?phoneNumber=${formattedNumber}${from}`);
        }
      } catch (e) {
        exceptionLogger(e);
        loginError.value =
          "Couldn't sign in, make sure the phone number is correct";
      }
    }
  }
  isLoading.value = false;
};

const title = 'Login at Eventful';

useHead({
  title,
});

useServerSeoMeta({
  title,
  description: 'Events where you meet wonderful people',
  ogDescription: 'Events where you meet wonderful people',
  ogImage: 'https://eventful.is/android-chrome-512x512.png',
  ogImageSecureUrl: 'https://eventful.is/android-chrome-512x512.png',
});
</script>

<style>
@import 'intl-tel-input/build/css/intlTelInput.css';

.iti__flag {
  background-image: url('../../node_modules/intl-tel-input/build/img/flags.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url('../../node_modules/intl-tel-input/build/img/flags@2x.png');
  }
}
</style>
